.navbar--shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.db_logo_icon {
  width: 20%;
  cursor: pointer;
}

.cancle_icon {
  margin-top: 5%;
  margin-left: 5%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.navbar__logo {
  flex-grow: 1;
}
.king-text {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 17px;
  text-align: center;
  color: #161616;
}
.navbar__menu {
  display: none;
  box-shadow: #7d7c7c;
}

.navbar__menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navbar__menu a img {
  height: 20px;
}
.navbar__menu a span {
  padding-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: #161616;
}
.navbar__menu a {
  border: 1px solid black;
  border-radius: 20px;
  padding: 4px 6px 8px 6px;
  text-decoration: none;
}

.navbar__icons {
  display: flex;
  align-items: center;
}

.navbar__icon {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.model_button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.navbar__menu-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar__menu-icon {
  width: 25px;
  height: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.navbar__menu-icon::before,
.navbar__menu-icon::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.navbar__menu-icon::before {
  top: -8px;
}

.navbar__menu-icon::after {
  bottom: -15px;
  background-color: black;
}

.navbar__menu.show-menu {
  align-items: center;
  display: block;
  position: absolute;
  top: 140%;
  right: 30px;
  background-color: white;
  width: 100%;
  z-index: 1;
}
.playStore a {
  cursor: pointer;
}
@media (min-width: 769px) {
  ._ul_alignmate {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 45px;
  }
  .king-text {
    display: none;
  }
  .navbar__menu{
    width: 35%;
  }
  .navbar__menu ul{
    width: 80%;
  }
  .playStore {
    width: 50% !important ;
  }
  .playStore a {
    padding-top: 4px;
    padding-bottom: 9px;
    padding-right: 14px;
    padding-left: 14px;
  }
  .playStore a img {
    width: 20%;
  }
  .king_icon {
    width: 100% !important;
  }
  .King_icon a img {
    width: 50%;
    height: 20px !important;
  }
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .navbar__menu {
    box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.5);
    width: 100% !important ;
    left: 0px;
  }
}
@media (min-width: 768px) {
  .Container_navBar {
    display: flex !important;
    justify-content: space-between !important;
  }
  .navbar__menu {
    display: flex;
    top: 0;
    left: 0;
    right: 20% !important;
  }

  .navbar__menu ul {
    flex-direction: row;
  }

  .navbar__menu-toggle {
    display: none;
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .king_icon {
    width: 10% !important ;
  }
  .playStore {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .playStore a {
    width: 250px;
    margin-left: 3%;
  }
  .navbar__menu li:nth-child(1) {
    order: 3;
  }

  .navbar__menu li:nth-child(2) {
    order: 3;
  }

  .navbar__menu li:nth-child(3) {
    order: 1;
  }
  .show-menu {
    height: 150vh;
  }
  .navbar__menu ul {
    list-style: none;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .navbar__menu li {
    width: 100%;
  }
  .navbar__menu a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 20px;
    border: 1px solid black;
    border-radius: 9px !important;
    text-decoration: none;
    font-size: 1.1rem;
  }
  .navbar__menu span {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .Nav__menu_li_a span {
    padding-left: 1% !important;
  }
  .navbar__menu {
    top: 0px !important;
  }

  .navbar__menu a span {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #161616;
  }
}

.playStore a:hover {
  opacity: 0.7;
}
