.container_footer {
  padding-left: 5%;
  padding-right: 5%;
}
.FooterHeading {
  width: 100%;
}
.FooterHeading h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 30px;
  color: #161616;
}
.FooterHeading p {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #656565;
}
.subscribe {
  padding: 9px 29px 9px 29px;
  background-color: #161616;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  border: none;
  border-radius: 7px;
  margin-left: 10px 10px 10px 10px;
}
.subscribe:hover,
.fOOTER_LOGO:hover {
  cursor: pointer;
}
.form-controls {
  width: 100%;
  padding: 10px;
  margin-left: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 9px;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .form-controls {
    justify-content: center;
    width: 90%;
  }
  .subscribe_btns {
  
    justify-content: center;
  width: 100%;
  }
  .rowa{
    text-align: center;
    align-items: center;
  }
  
  
  .FooterHeading_h2 {
    padding: 10px;
    text-align: center;
  }

  .detsilsFooter {
    padding: 40px;
  }
  .fOOTER_LOGO {
    margin-bottom: 5%;
  }
}
.detsilsFooter {
  display: flex !important;
  flex-wrap: wrap !important;
}

.fOOTER_LOGO {
  display: flex;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 39px;
  text-align: center;
  color: #0c3c76;
}
.fOOTER_LOGO span {
  position: relative;
  padding-left: 12px;
}
.fotter_detailsss {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-around;
}
.fotter_detailsss h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #161616;
}
.fotter_detailsss p {
  padding-top: 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #717171;
}
.lineWidth_top {
  margin-bottom: 50px;
  margin-top: 5px;
  left: 98.5px;
  border: 1.5px solid #e9e9e9;
}
.lineWidth {
  margin-bottom: 18px;
  margin-top: 50px;
  left: 98.5px;
  border: 1.5px solid #e9e9e9;
}
.Footer_bottom img {
  margin-right: 4px;
  width: 18px;
  height: 17px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.Footer_bottom span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #161616;
}
.Footer_bottom p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #626060;
}
.footer_responsive {
  display: flex;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .Footer_bottom {
    display: flex;
  }
  .social_medai {
    bottom: 100px;
    display: flex;
    right: 5%;
    position: relative;
  }
  .footer_responsive {
    display: flex;
    position: absolute;
    text-align: center;
    justify-content: center;
    padding-left: 5%;
  }
  .fotter_detailsss {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
  }
}
.social_medai span {
  padding-left: 25px;
}
.social_medai img {
  width: 25px;
}
.subscribe:hover,
.fOOTER_LOGO:hover,
.Footer_bottom img:hover,
.social_medai img:hover,
.fotter_detailsss p:hover {
  cursor: pointer;
}
.newlatter_error{
  color: red;
}
