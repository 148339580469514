.container_premium {
  padding-left: 5%;
  padding-right: 5%;
}
.premium_header {
  margin: 40px;
}
.premium_header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #161616;
}
.premium_texts {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 37px;
  text-align: center;
  color: #161616;
}
.premium_text_two {
  padding: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  color: #323232;
}
.premium_liness {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  text-align: center;

  color: #323232;
}
.button_premimum button {
  padding: 5px 25px 5px 25px;
  border: none;
  background: linear-gradient(90deg, #f68e43 0%, #fecd63 100%);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}
.premium_last_line {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 32px;
  text-align: center;

  color: #464646;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .premium_header h1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 49px;
    color: #161616;
  }
  .premium_texts {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;

    color: #161616;
  }
  .premium_text_two {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #323232;
  }
  .premium_lines {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
  .premium_last_line {
    margin-top: 10px !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }
}
